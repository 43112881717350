import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import api from "../../api/api";
import {
  Button,
  TextField,
  TextareaAutosize,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Divider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export const Edit = ({ categoryToEdit, setCategoryToEdit, setOption }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: categoryToEdit?.name,
      description: categoryToEdit?.description || "",
    },
  });
  const [openDeleteProduct, setOpenDeleteProduct] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productToEdit, setProductToEdit] = useState({});
  const [productIDs, setProductIDs] = useState([]);
  const [products, setProducts] = useState([]);

  // Fetch products once
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const res = await api.get("products?take=500");
        if (res.status === 200) {
          setProducts(res.data.products);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  // Filter products by categoryToEdit.productIds
  const filteredProducts = products.filter((product) =>
    categoryToEdit.productIds?.includes(product.id)
  );

  const onSubmit = (data) => {
    const sendData = {
      name: data.name,
      disconnectProducts: productIDs,
    };
    api
      .put(`/categories/${categoryToEdit.id}`, sendData)
      .then((res) => {
        if (res.status === 200) {
          setOption("view");
        }
      })
      .catch((error) => console.error("Error updating category:", error));
  };

  const handleRemoveProduct = () => {
    // Remove product visually
    const updatedProducts = categoryToEdit?.productIds?.filter(
      (product) => product !== productToEdit.id
    );

    console.log("New Id Products:", updatedProducts);
    setProductIDs([...productIDs, productToEdit.id]); // Track removed product IDs
    setCategoryToEdit({ ...categoryToEdit, productIds: updatedProducts }); // Update category products
    setOpenDeleteProduct(false);
  };

  return (
    <>
      <div>
        <Typography variant="h5">
          Editando Categoria: {categoryToEdit.name}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)} className="mt-4 space-y-4">
          <TextField
            {...register("name", { required: "Nome é obrigatório" })}
            label="Nome"
            variant="outlined"
            fullWidth
            error={!!errors.name}
            helperText={errors.name?.message}
          />
          <TextareaAutosize
            {...register("description")}
            placeholder="Descrição"
            rows={4}
            className="w-full border rounded-md p-2"
          />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" component="div" mt={4}>
                Produtos na Categoria
              </Typography>
              <List className="max-h-[800px] overflow-auto" dense>
                {loading
                  ? "Carregando..."
                  : filteredProducts.length > 0
                  ? filteredProducts.map((product) => (
                      <>
                        <ListItem>
                          <ListItemText primary={product.name} />
                          <ListItemSecondaryAction>
                            <Tooltip title="Desvincular Produto">
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                color="error"
                                onClick={() => {
                                  setOpenDeleteProduct(true);
                                  setProductToEdit(product);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                      </>
                    ))
                  : "Sem Produtos"}
              </List>
            </Grid>
          </Grid>
          <Button type="submit" variant="contained" color="success">
            Salvar
          </Button>
          <Button
            type="button"
            variant="contained"
            color="primary"
            sx={{ marginLeft: 1 }}
            onClick={() => setOption("view")}
          >
            Voltar
          </Button>
        </form>
      </div>

      {/* Modal to Confirm Product Removal */}
      <Dialog
        open={openDeleteProduct}
        onClose={() => setOpenDeleteProduct(false)}
      >
        <DialogTitle>Confirmar Desvinculação do Produto</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza de que deseja desvincular o produto{" "}
            <span className="text-black underline">{productToEdit.name}</span>{" "}
            da categoria{" "}
            <span className="text-black underline">{categoryToEdit.name}</span>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteProduct(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleRemoveProduct} color="error">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
