import React, { useState } from "react";
import CollapsibleTable from "./Table";
import api from "../../api/api";

export const View = ({ category, setOption, setSelectedCategory, option }) => {
  const handleSelect = (item, isCategory) => {
    // Atualiza o estado do componente pai com o item selecionado
    setSelectedCategory(item);
  };
  return (
    <div>
      <CollapsibleTable
        onSelect={handleSelect} // Passando a nova função
        categories={category}
        setOption={setOption}
      />
    </div>
  );
};
