import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  useForm,
  useFieldArray,
  Controller,
  FormProvider,
} from "react-hook-form";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
  InputAdornment,
  Typography,
  Divider,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import AutoCompleteCategory from "../Categorias/AutoCompleteCategory";
import api from "../../api/api";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import dayjs from "dayjs";
import "dayjs/locale/pt-br"; // Importa o locale pt-br do dayjs

export const QuickEditProduct = ({
  visible,
  product,
  onClose,
  onSave,
  setWarning,
  handleOpenSnackbar,
}) => {
  const [isBuildingMap, setIsBuildingMap] = useState(true);
  const [categoryMap, setCategoryMap] = useState({});
  const [initialCategoryObjects, setInitialCategoryObjects] = useState([]);
  const methods = useForm({
    defaultValues: product,
    mode: "all", // Adiciona esta linha para garantir a validação contínua dos campos
  });
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors, isValid },
    getValues,
    reset,
  } = methods;

  const [subcategories, setSubCategories] = useState([]);

  useEffect(() => {
    const formattedProduct = {
      ...product,
      discountCustomerDueDate: product.discountCustomerDueDate
        ? dayjs(product.discountCustomerDueDate).format("YYYY-MM-DD")
        : "",
      discountVendorDueDate: product.discountVendorDueDate
        ? dayjs(product.discountVendorDueDate).format("YYYY-MM-DD")
        : "",
    };
    reset(formattedProduct);
  }, [product, reset]);

  // Use `useFieldArray` para gerenciar URLs de imagens
  const {
    fields: imageFields,
    append: appendImage,
    remove: removeImage,
  } = useFieldArray({
    control,
    name: "imgUrl",
  });

  // Função para adicionar uma nova URL de imagem, com limite de 5
  const addImage = () => {
    if (imageFields.length < 5) {
      appendImage("");
    }
  };
  useEffect(() => {
    reset(product); // Reinicializa o formulário com os novos valores do produto
  }, [product, reset]);

  const buildCategoryMap = (categories, map = {}) => {
    categories.forEach((category) => {
      map[category.id] = category;
      if (category.children && category.children.length > 0) {
        buildCategoryMap(category.children, map);
      }
    });
    return map;
  };

  useEffect(() => {
    api
      .get("/categories/nested")
      .then((res) => {
        const response = res.data.categories;
        setSubCategories(response);
        const newCategoryMap = buildCategoryMap(response);
        setCategoryMap(newCategoryMap);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (Object.keys(categoryMap).length > 0 && product.categoryIds) {
      const newInitialCategoryObjects = product.categoryIds.map(
        (categoryId) => {
          const categoryObject = categoryMap[categoryId];
          return {
            id: categoryId,
            label: categoryObject
              ? categoryObject.name
              : "Categoria não encontrada",
          };
        }
      );

      // Verifique se todos os categoryIds foram mapeados antes de remover o carregamento
      const allFound = newInitialCategoryObjects.every(
        (category) => category.label !== "Categoria não encontrada"
      );
      if (allFound) {
        setInitialCategoryObjects(newInitialCategoryObjects);
        console.log("Initial category", newInitialCategoryObjects);
        setIsBuildingMap(false);
      }
    }
  }, [categoryMap, product.categoryIds]);

  const validatePositive = (value) =>
    parseFloat(value) >= 0 || "O valor deve ser maior ou igual a zero";

  const onSubmit = (data) => {
    // Certifique-se de que as datas sejam convertidas para o formato ISO
    const formattedData = {
      ...data,
      discountCustomerDueDate: data.discountCustomerDueDate
        ? dayjs(data.discountCustomerDueDate).toISOString()
        : null,
      discountVendorDueDate: data.discountVendorDueDate
        ? dayjs(data.discountVendorDueDate).toISOString()
        : null,
    };
    api
      .put(`/products/${product.id}`, formattedData)
      .then((response) => {
        if (response.status === 200) {
          setWarning({
            type: "success",
            message: "Produto Editado",
          });
          handleOpenSnackbar();
          onSave();
        }
      })
      .catch((error) => {
        const issues = error.response.data.issues;
        setWarning({
          type: "error",
          message:
            "Houve uma falha ao tentar salvar o Produto, Tente Novamente Mais Tarde",
        });
        handleOpenSnackbar();
      });
  };

  return (
    <Drawer
      open={!!product}
      onClose={onClose}
      anchor="right"
      sx={{ "& .MuiDrawer-paper": { width: "100%", maxWidth: 500 } }}
    >
      <Box sx={{ p: 3 }}>
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" sx={{ mb: 3 }}>
          Editar Produto
        </Typography>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              label="Nome do Produto"
              {...register("name", {
                required: "Nome é obrigatório",
              })}
              error={!!errors.name}
              helperText={errors.name?.message}
              fullWidth
              margin="normal"
            />
            <Divider />
            <TextField
              label="Descrição Curta"
              defaultValue={product?.shortDesc}
              {...register("shortDesc")}
              error={!!errors.name}
              helperText={errors.name?.message}
              fullWidth
              margin="normal"
            />
            <Divider />
            <div>
              <InputLabel id="type-label">Tipo</InputLabel>
              <Controller
                name="type"
                control={control}
                rules={{ required: "Tipo é um campo Obrigatório" }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Select
                    labelId="type-label"
                    label="Tipo"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                  >
                    <MenuItem value="SEM_TIPO">Selecione uma Opção</MenuItem>
                    <MenuItem value="ACESSORIOS">Acessórios</MenuItem>
                    <MenuItem value="CONDICIONADOR">Condicionador</MenuItem>
                    <MenuItem value="FINALIZADORES_E_OLEOS">
                      Finalizadores e Óleos
                    </MenuItem>
                    <MenuItem value="MASCARA">Máscara</MenuItem>
                    <MenuItem value="QUIMICAS">Químicas</MenuItem>
                    <MenuItem value="SALAO">Salão</MenuItem>
                    <MenuItem value="SHAMPOO">Shampoo</MenuItem>
                    <MenuItem value="TRATAMENTOS">Tratamentos</MenuItem>
                    <MenuItem value="KIT">Kit</MenuItem>
                  </Select>
                )}
              />
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={product?.forYou}
                  {...register("forYou")}
                />
              }
              label="Para Você"
            />
            <Divider sx={{ mb: 2 }} />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <TextField
                label="Largura"
                defaultValue={product?.width}
                {...register("width", {
                  valueAsNumber: true,
                  validate: validatePositive,
                  required: "Largura é Obrigatório",
                })}
                error={!!errors.name}
                helperText={errors.name?.message}
                type="number"
                sx={{ mb: "1rem", maxWidth: "32%" }}
              />

              <TextField
                label="Altura"
                defaultValue={product?.height}
                {...register("height", {
                  valueAsNumber: true,
                  validate: validatePositive,
                  required: "Altura é Obrigatório",
                })}
                error={!!errors.name}
                helperText={errors.name?.message}
                type="number"
                sx={{ mb: "1rem", maxWidth: "32%" }}
              />

              <TextField
                label="Comprimento"
                defaultValue={product?.length}
                {...register("length", {
                  valueAsNumber: true,
                  validate: validatePositive,
                  required: "Comprimento é Obrigatório",
                })}
                error={!!errors.name}
                helperText={errors.name?.message}
                sx={{ maxWidth: "32%" }}
                type="number"
              />
            </Box>
            <TextField
              label="Peso"
              defaultValue={product?.weight}
              {...register("weight", {
                valueAsNumber: true,
                validate: validatePositive,
                required: "Peso é Orbrigatório",
              })}
              error={!!errors.name}
              helperText={errors.name?.message}
              type="number"
              fullWidth
              margin="normal"
            />
            <p className="text-xs text-gray-600 mb-1">
              1000 = 1kg/L 100 = 100g/ml
            </p>
            <Divider />
            <TextField
              label="Preço de Custo"
              {...register("productCost", {
                required: "Preço para salão é obrigatório",
                valueAsNumber: true,
                validate: (value) =>
                  value >= 0 || "O preço não pode ser menor que zero",
              })}
              type="number"
              inputProps={{
                step: "any", // This allows for decimal numbers
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
              error={!!errors.productCost}
              helperText={errors.productCost?.message}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Preço Cliente"
              {...register("customerPrice", {
                required: "Preço é obrigatório",
                valueAsNumber: true,
                validate: (value) =>
                  value >= 0 || "O preço não pode ser menor que zero",
              })}
              type="number"
              inputProps={{
                step: "any", // This allows for decimal numbers
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
              error={!!errors.customerPrice}
              helperText={errors.customerPrice?.message}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Preço Cliente Promocional"
              {...register("customerPriceNew", {
                required: "Preço é obrigatório",
                valueAsNumber: true,
                validate: (value) =>
                  value >= 0 || "O preço não pode ser menor que zero",
              })}
              type="number"
              inputProps={{
                step: "any", // This allows for decimal numbers
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
              error={!!errors.customerPriceNew}
              helperText={errors.customerPriceNew?.message}
              fullWidth
              margin="normal"
            />
            <Controller
              name="discountCustomerDueDate"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Vencimento Promoção Cliente"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={field.value || null} // Garante que não será undefined
                />
              )}
            />
            <TextField
              label="Preço Salão"
              {...register("vendorPrice", {
                required: "Preço é obrigatório",
                valueAsNumber: true,
                validate: (value) =>
                  value >= 0 || "O preço não pode ser menor que zero",
              })}
              type="number"
              inputProps={{
                step: "any", // This allows for decimal numbers
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
              error={!!errors.vendorPrice}
              helperText={errors.vendorPrice?.message}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Preço Salão Promocional"
              {...register("vendorPriceNew", {
                required: "Preço para salão é obrigatório",
                valueAsNumber: true,
                validate: (value) =>
                  value >= 0 || "O preço não pode ser menor que zero",
              })}
              type="number"
              inputProps={{
                step: "any", // This allows for decimal numbers
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
              error={!!errors.vendorPriceNew}
              helperText={errors.vendorPriceNew?.message}
              fullWidth
              margin="normal"
            />
            <Controller
              name="discountVendorDueDate"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Vencimento Promoção Salão"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={field.value || null} // Garante que não será undefined
                />
              )}
            />
            <Divider />
            <Typography variant="h6" sx={{ mb: 2 }}>
              Imagens do Produto
            </Typography>
            {imageFields.map((field, index) => (
              <Box
                key={field.id}
                sx={{ display: "flex", alignItems: "center", mb: 2 }}
              >
                <TextField
                  label={`URL da Imagem ${index + 1}`}
                  {...register(`imgUrl.${index}`, {
                    required: "URL da imagem é obrigatória",
                  })}
                  fullWidth
                  margin="normal"
                />
                <IconButton onClick={() => removeImage(index)}>
                  <CloseOutlined />
                </IconButton>
              </Box>
            ))}
            {imageFields.length < 5 && (
              <Button onClick={addImage} variant="contained" sx={{ mb: 2 }}>
                Adicionar Imagem
              </Button>
            )}
            <Divider />
            <Typography variant="h6" sx={{ mt: 1 }}>
              Fiscal
            </Typography>
            <TextField
              label={"NCM"}
              {...register("ncm", {
                required: "NCM é um campo Obrigatório",
              })}
              fullWidth
              margin="normal"
            />
            <TextField
              label={"CEST"}
              {...register("cest", {
                required: "CEST é um campo Obrigatório",
              })}
              fullWidth
              margin="normal"
            />
            <Divider />
            <Controller
              name="categoryIds"
              control={control}
              render={({ field }) =>
                isBuildingMap ? (
                  <span>carregando...</span>
                ) : (
                  <AutoCompleteCategory
                    categories={subcategories}
                    initialValue={initialCategoryObjects}
                    fullWidth
                    margin="normal"
                  />
                )
              }
            />
            <Divider />
            <div className="flex flex-col">
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 2 }}
                disabled={!isValid} // O botão será desabilitado se o formulário não for válido
              >
                Salvar Edição
              </Button>
              {!isValid ? (
                <p className=" text-red-600">
                  Preencha todos os campos antes de salvar!
                </p>
              ) : null}
            </div>
          </form>
        </FormProvider>
      </Box>
    </Drawer>
  );
};
