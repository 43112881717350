import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import api from "../../api/api";
import {
  FormProvider,
  useForm,
  useFieldArray,
  Controller,
} from "react-hook-form";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
  InputAdornment,
  Typography,
  Divider,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import AutoCompleteCategory from "../Categorias/AutoCompleteCategory";
import Loading from "../Loading";
import dayjs from "dayjs";
import "dayjs/locale/pt-br"; // Importa o locale pt-br do dayjs

const EditProduct = ({
  product,
  onClose,
  onSave,
  setWarning,
  handleOpenSnackbar,
}) => {
  const [isBuildingMap, setIsBuildingMap] = useState(true);
  const [categoryMap, setCategoryMap] = useState({});
  const [initialCategoryObjects, setInitialCategoryObjects] = useState([]);

  const methods = useForm({
    defaultValues: product,
    mode: "all", // Adiciona esta linha para garantir a validação contínua dos campos
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors, isValid },
    getValues,
    reset,
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "description",
  });

  // Use `useFieldArray` para gerenciar URLs de imagens
  const {
    fields: imageFields,
    append: appendImage,
    remove: removeImage,
  } = useFieldArray({
    control,
    name: "imgUrl",
  });

  // Função para adicionar uma nova URL de imagem, com limite de 5
  const addImage = () => {
    if (imageFields.length < 5) {
      appendImage("");
    }
  };

  // Função para adicionar uma nova descrição
  const addDescription = () => {
    if (fields.length < 3) {
      append({ title: "", body: "" });
    }
  };
  const [subcategories, setSubCategories] = useState([]);
  const description = watch("description");
  const canAddMore = description.length < 3;
  const [body0, setBody0] = useState(""); // Estado para manter o valor do editor
  const [body1, setBody1] = useState(""); // Estado para manter o valor do editor
  const [body2, setBody2] = useState(""); // Estado para manter o valor do editor

  const handleQuillChange = (value, index) => {
    index === 0
      ? setBody0(value)
      : index === 1
      ? setBody1(value)
      : setBody2(value); // Atualize o estado local

    setValue(`description[${index}].body`, value, {
      shouldValidate: true,
    });
  };

  // Sempre que o item mudar, atualize os valores do formulário
  useEffect(() => {
    if (product.description && product.description.length > 0) {
      setValue("description[0].body", product.description[0].body);
      setBody0(product.description[0].body);
      if (product.description.length > 1) {
        setValue("description[1].body", product.description[1].body);
        setBody1(product.description[1].body);
      }
      if (product.description.length > 2) {
        setValue("description[2].body", product.description[2].body);
        setBody2(product.description[2].body);
      }
    }
  }, [product, setValue]);

  useEffect(() => {
    reset(product); // Reinicializa o formulário com os novos valores do produto
  }, [product, reset]);

  const buildCategoryMap = (categories, map = {}) => {
    categories.forEach((category) => {
      map[category.id] = category;
      if (category.children && category.children.length > 0) {
        buildCategoryMap(category.children, map);
      }
    });
    return map;
  };

  useEffect(() => {
    api
      .get("/categories/nested")
      .then((res) => {
        const response = res.data.categories;
        setSubCategories(response);
        const newCategoryMap = buildCategoryMap(response);
        setCategoryMap(newCategoryMap);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const newInitialCategoryObjects = product.categoryIds.map((categoryId) => {
      const categoryObject = categoryMap[categoryId];
      return {
        id: categoryId,
        label: categoryObject
          ? categoryObject.name
          : "Categoria não encontrada",
      };
    });

    // Verifique se todos os categoryIds foram mapeados antes de remover o carregamento
    const allFound = newInitialCategoryObjects.every(
      (category) => category.label !== "Categoria não encontrada"
    );
    if (allFound) {
      setInitialCategoryObjects(newInitialCategoryObjects);
      setIsBuildingMap(false);
    }
  }, [categoryMap, product.categoryIds]);

  const validatePositive = (value) =>
    parseFloat(value) >= 0 || "O valor deve ser maior ou igual a zero";

  const imageInput = watch("images");

  const onSubmit = (data) => {
    // Certifique-se de que as datas sejam convertidas para o formato ISO
    const formattedData = {
      ...data,
      discountCustomerDueDate: data.discountCustomerDueDate
        ? dayjs(data.discountCustomerDueDate).toISOString()
        : null,
      discountVendorDueDate: data.discountVendorDueDate
        ? dayjs(data.discountVendorDueDate).toISOString()
        : null,
    };
    api
      .put(`/products/${product.id}`, formattedData)
      .then((response) => {
        if (response.status === 200) {
          setWarning({
            type: "success",
            message: "Produto Editado",
          });
          handleOpenSnackbar();
          onSave();
        }
      })
      .catch((error) => {
        const issues = error.response.data.issues;
        setWarning({
          type: "error",
          message:
            "Houve uma falha ao tentar salvar o Produto, Tente Novamente Mais Tarde",
        });
        handleOpenSnackbar();
      });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ mb: 3 }}>
        Editar Produto
      </Typography>
      <button type="button" className="text-blue-500" onClick={onClose}>
        Fechar
      </button>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label={"Nome do Produto"}
            {...register("name", {
              required: "Nome é um campo obrigatório",
            })}
            error={!!errors.name}
            helperText={errors.name?.message}
            fullWidth
            margin="normal"
          />
          <Divider />
          <Typography variant="h6" sx={{ mb: 2 }}>
            Descrições Detalhadas
          </Typography>
          {fields.map((item, index) => (
            <Box key={item.id} sx={{ mb: 2 }}>
              <TextField
                label={`Título ${index + 1}`}
                {...register(`description[${index}].title`, {
                  required: "Título é obrigatório",
                })}
                fullWidth
                margin="normal"
                error={!!errors.description?.[index]?.title}
                helperText={errors.description?.[index]?.title?.message}
              />
              <ReactQuill
                theme="snow"
                onChange={(value) => handleQuillChange(value, index)}
                value={index === 0 ? body0 : index === 1 ? body1 : body2} // Supondo que `item.body` armazene o valor atual para este campo
                modules={EditProduct.modules} // Certifique-se de definir 'EditProduct.modules'
                formats={EditProduct.formats} // e 'EditProduct.formats' conforme necessário
                placeholder="Escreva sua mensagem..."
                className="bg-white h-[full] max-w-3xl"
              />
              <Button
                startIcon={<CloseOutlined />}
                onClick={() => remove(index)}
                sx={{ mt: 1 }}
              >
                Remover Descrição
              </Button>
            </Box>
          ))}
          {canAddMore && (
            <Button onClick={addDescription} variant="contained" sx={{ mt: 1 }}>
              Adicionar Descrição
            </Button>
          )}
          <TextField
            label={"Descrição Curta"}
            {...register("shortDesc", {
              required: "Descrição Curta é um campo obrigatório",
            })}
            multiline
            rows={2}
            fullWidth
            margin="normal"
          />
          <Divider />
          <div>
            <InputLabel id="type-label">Tipo</InputLabel>
            <Controller
              name="type"
              control={control}
              rules={{ required: "Tipo é um campo Obrigatório" }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Select
                  labelId="type-label"
                  label="Tipo"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                >
                  <MenuItem value="SEM_TIPO">Selecione uma Opção</MenuItem>
                  <MenuItem value="ACESSORIOS">Acessórios</MenuItem>
                  <MenuItem value="CONDICIONADOR">Condicionador</MenuItem>
                  <MenuItem value="FINALIZADORES_E_OLEOS">
                    Finalizadores e Óleos
                  </MenuItem>
                  <MenuItem value="MASCARA">Máscara</MenuItem>
                  <MenuItem value="QUIMICAS">Químicas</MenuItem>
                  <MenuItem value="SALAO">Salão</MenuItem>
                  <MenuItem value="SHAMPOO">Shampoo</MenuItem>
                  <MenuItem value="TRATAMENTOS">Tratamentos</MenuItem>
                  <MenuItem value="KIT">Kit</MenuItem>
                </Select>
              )}
            />
          </div>
          <FormControlLabel
            control={<Checkbox {...register("forYou")} />}
            label="Para você"
            margin="normal"
          />
          <Divider sx={{ mb: 2 }} />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <TextField
              label="Largura"
              {...register("width", {
                required: "Largura é obrigatório",
                valueAsNumber: true,
                validate: validatePositive,
              })}
              type="number"
              sx={{ mb: "1rem", maxWidth: "32%" }}
            />

            <TextField
              label="Altura"
              {...register("height", {
                required: "Altura é obrigatório",
                valueAsNumber: true,
                validate: validatePositive,
              })}
              type="number"
              sx={{ mb: "1rem", maxWidth: "32%" }}
            />

            <TextField
              label="Comprimento"
              {...register("length", {
                required: "Comprimento é obrigatório",
                valueAsNumber: true,
                validate: validatePositive,
              })}
              sx={{ maxWidth: "32%" }}
              type="number"
            />
          </Box>
          <TextField
            label="Peso"
            type="number"
            {...register("weight", {
              required: "Peso é obrigatório",
              valueAsNumber: true,
              validate: validatePositive,
            })}
            fullWidth
            margin="normal"
            inputProps={{ step: "any" }}
          />
          <p className="text-xs text-gray-600">1000 = 1kg/L 100 = 100g/ml</p>
          <Divider />
          <div className="flex flex-col justify-evenly">
            <TextField
              label="Preço de Custo"
              {...register("productCost", {
                required: "Preço para salão é obrigatório",
                valueAsNumber: true,
                validate: (value) =>
                  value >= 0 || "O preço não pode ser menor que zero",
              })}
              type="number"
              inputProps={{
                step: "any", // This allows for decimal numbers
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
              error={!!errors.productCost}
              helperText={errors.productCost?.message}
              margin="normal"
            />
            <TextField
              label="Preço Cliente"
              {...register("customerPrice", {
                required: "Preço é obrigatório",
                valueAsNumber: true,
                validate: (value) =>
                  value >= 0 || "O preço não pode ser menor que zero",
              })}
              type="number"
              inputProps={{
                step: "any", // This allows for decimal numbers
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
              error={!!errors.customerPrice}
              helperText={errors.customerPrice?.message}
              margin="normal"
            />
            <TextField
              label="Preço Cliente Promocional"
              {...register("customerPriceNew", {
                required: "Preço é obrigatório",
                valueAsNumber: true,
                validate: (value) =>
                  value >= 0 || "O preço não pode ser menor que zero",
              })}
              type="number"
              inputProps={{
                step: "any", // This allows for decimal numbers
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
              error={!!errors.customerPriceNew}
              helperText={errors.customerPriceNew?.message}
              margin="normal"
            />
            <Controller
              name="discountCustomerDueDate"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Vencimento Promoção Cliente"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              )}
            />
            <TextField
              label="Preço Salão"
              {...register("vendorPrice", {
                required: "Preço para salão é obrigatório",
                valueAsNumber: true,
                validate: (value) =>
                  value >= 0 || "O preço não pode ser menor que zero",
              })}
              type="number"
              inputProps={{
                step: "any", // This allows for decimal numbers
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
              error={!!errors.vendorPrice}
              helperText={errors.vendorPrice?.message}
              margin="normal"
            />
            <TextField
              label="Preço Salão Promocional"
              {...register("vendorPriceNew", {
                required: "Preço para salão é obrigatório",
                valueAsNumber: true,
                validate: (value) =>
                  value >= 0 || "O preço não pode ser menor que zero",
              })}
              type="number"
              inputProps={{
                step: "any", // This allows for decimal numbers
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
              error={!!errors.vendorPriceNew}
              helperText={errors.vendorPriceNew?.message}
              margin="normal"
            />
            <Controller
              name="discountVendorDueDate"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Vencimento Promoção Salão"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              )}
            />
          </div>
          <Divider />
          <Typography variant="h6" sx={{ mb: 2 }}>
            Imagens do Produto
          </Typography>
          {imageFields.map((field, index) => (
            <Box
              key={field.id}
              sx={{ display: "flex", alignItems: "center", mb: 2 }}
            >
              <TextField
                label={`URL da Imagem ${index + 1}`}
                {...register(`imgUrl.${index}`, {
                  required: "URL da imagem é obrigatória",
                })}
                fullWidth
                margin="normal"
              />
              <IconButton onClick={() => removeImage(index)}>
                <CloseOutlined />
              </IconButton>
            </Box>
          ))}
          {imageFields.length < 5 && (
            <Button onClick={addImage} variant="contained" sx={{ mb: 2 }}>
              Adicionar Imagem
            </Button>
          )}
          <Divider />
          <TextField
            label={"NCM"}
            {...register("ncm", {
              required: "NCM é um campo Obrigatório",
            })}
            fullWidth
            margin="normal"
          />
          <TextField
            label={"CEST"}
            {...register("cest", {
              required: "CEST é um campo Obrigatório",
            })}
            fullWidth
            margin="normal"
          />
          <Divider />
          <Controller
            name="categoryIds"
            control={control}
            render={({ field }) =>
              isBuildingMap ? (
                <span>carregando...</span>
              ) : (
                <AutoCompleteCategory
                  categories={subcategories}
                  initialValue={initialCategoryObjects}
                  fullWidth
                  margin="normal"
                />
              )
            }
          />
          <Divider />
          <div className="flex flex-col">
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
              disabled={!isValid}
            >
              Atualizar
            </Button>
            {!isValid && (
              <p className="text-red-600">
                Preencha todos os campos antes de salvar!
              </p>
            )}
          </div>
        </form>
      </FormProvider>
    </Box>
  );
};

export default EditProduct;

// Adicione módulos e formatos que você deseja habilitar no Quill editor
EditProduct.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    ["clean"],
  ],
  clipboard: {
    // Colar sem formatação
    matchVisual: true,
  },
};

EditProduct.formats = [
  "header",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
];
